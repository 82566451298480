import { makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import React, { VFC } from "react";
import { QuestStepProps } from "../quests.types";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    content: {
      padding: theme.spacing(3)
    }
  }),
  {
    classNamePrefix: "FindTheThing",
  }
);

export type FindTheThingJSSClassKey = keyof ReturnType<typeof useStyles>;

export type FindTheThingProps = QuestStepProps & {
  classes?: Partial<ClassNameMap<FindTheThingJSSClassKey>>;
  className?: string;
};

export const FindTheThing: VFC<FindTheThingProps> = ({ className, classes: extClasses }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <div className={clsx(classes.root, className)}>
      <Typography className={classes.content}>
        Hey this is some instructions for the next quest step. This popover will look nicer sometime.
      </Typography>
    </div>
  );
};
